import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { Table } from 'antd';
import { useUserContext } from '../context/UserContext';
import './CallCustomer.css';

const CallCustomer = () => {
  const { user } = useUserContext();
  const [customers, setCustomers] = useState([]);
  const [clients, setClients] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [note, setNote] = useState('');
  const [callLogs, setCallLogs] = useState([]);
  const [callCode, setCallCode] = useState('');
  const [callDate, setCallDate] = useState('');
  const [lastDateTime, setLastDateTime] = useState('');
  const [payDate, setPayDate] = useState('');
  const [filters, setFilters] = useState({
    customer: '',
    client: '',
    riskMinimum: ''
  });
  const [showFilters, setShowFilters] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
  });
  const navigate = useNavigate();

  const currentCustomer = customers[currentIndex]; // Declare currentCustomer here

  const fetchCustomers = useCallback(async (page = 1, newIndex = 0) => {
    try {
      const response = await axiosInstance.get(`${config.API_URL}/customers/filtered-customers`, {
        params: { ...filters, page },
      });
      setCustomers(response.data.customers);
      setPagination({
        currentPage: response.data.currentPage,
        totalPages: response.data.totalPages,
        totalItems: response.data.totalItems,
      });
      setCurrentIndex(newIndex);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  }, [filters]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  const fetchClients = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${config.API_URL}/clients`);
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  }, []);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const fetchCallLogs = useCallback(async () => {
    if (!currentCustomer) return;

    try {
      const response = await axiosInstance.get(`${config.API_URL}/customer-call-logs/${currentCustomer.id}`);
      setCallLogs(response.data);
    } catch (error) {
      console.error('Error fetching call logs:', error);
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (currentCustomer) {
      fetchCallLogs();
    }
  }, [currentCustomer, fetchCallLogs]);

  const saveCallLog = async () => {
    if (!currentCustomer) return;

    const validCallDate = callDate ? new Date(callDate).toISOString() : null;
    const validLastDateTime = lastDateTime ? new Date(lastDateTime).toISOString() : null;
    const validPayDate = payDate ? new Date(payDate).toISOString() : null;

    try {
      const response = await axiosInstance.post(`${config.API_URL}/customer-call-logs`, {
        customerId: currentCustomer.id,
        userId: user.id,
        callCode,
        callDate: validCallDate,
        lastDateTime: validLastDateTime,
        payDate: validPayDate,
        comments: note
      });

      console.log('Call log saved:', response.data);
      setCallCode('');
      setCallDate('');
      setLastDateTime('');
      setPayDate('');
      setNote('');

      // Refetch call logs after saving
      fetchCallLogs();
    } catch (error) {
      console.error('Error saving call log:', error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleClearFilters = () => {
    setFilters({
      customer: '',
      client: '',
      riskMinimum: ''
    });
    setShowFilters(false);
    fetchCustomers();
  };

  const handleNext = useCallback(() => {
    if (currentIndex === customers.length - 1) {
      if (pagination.currentPage < pagination.totalPages) {
        fetchCustomers(pagination.currentPage + 1);
      }
    } else {
      setCurrentIndex(prev => prev + 1);
    }
  }, [currentIndex, customers.length, pagination.currentPage, pagination.totalPages, fetchCustomers]);

  const handlePrevious = useCallback(() => {
    if (currentIndex === 0) {
      if (pagination.currentPage > 1) {
        fetchCustomers(pagination.currentPage - 1, customers.length - 1);
      }
    } else {
      setCurrentIndex(prev => prev - 1);
    }
  }, [currentIndex, customers.length, pagination.currentPage, fetchCustomers]);

  const handleViewSchedule = useCallback((scheduleId) => {
    navigate(`/schedules/${scheduleId}`);
  }, [navigate]);

  const handleEmailDelinquentInvoices = () => {
    if (!currentCustomer) return;

    const delinquentInvoices = currentCustomer.invoices.filter(invoice => invoice.balance > 0);
    const invoiceDetails = delinquentInvoices.map(invoice => `
      Invoice Number: ${invoice.invoiceNumber}
      Invoice Date: ${invoice.invoiceDate}
      Due Date: ${invoice.dueDate}
      Amount: ${invoice.amount}
      Balance: ${invoice.balance}
      Status: ${invoice.status}
    `).join('\n\n');

    const emailBody = `
      Dear ${currentCustomer.name},

      Please find below the details of your delinquent invoices:

      ${invoiceDetails}

      Best regards,
      [Your Company Name]
    `;

    window.location.href = `mailto:?subject=Delinquent Invoices&body=${encodeURIComponent(emailBody)}`;
  };

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'ArrowLeft') {
      handlePrevious();
    } else if (e.key === 'ArrowRight') {
      handleNext();
    }
  }, [handlePrevious, handleNext]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const columns = React.useMemo(() => [
    { Header: 'Invoice Number', accessor: 'invoiceNumber' },
    { Header: 'Invoice Date', accessor: 'invoiceDate', Cell: ({ value }) => value.split('T')[0] },
    { Header: 'Due Date', accessor: 'dueDate', Cell: ({ value }) => value.split('T')[0] },
    { Header: 'Amount', accessor: 'amount', Cell: ({ value }) => `$${parseFloat(value).toFixed(2)}` },
    { Header: 'Balance', accessor: 'balance', Cell: ({ value }) => `$${parseFloat(value).toFixed(2)}` },
    { Header: 'Status', accessor: 'status' },
    {
      Header: 'Actions',
      Cell: ({ row }) => (
        <button onClick={() => handleViewSchedule(row.original.scheduleId)}>View Schedule</button>
      )
    },
  ], [handleViewSchedule]);

  const tableInstance = useTable({ columns, data: currentCustomer ? currentCustomer.invoices : [] });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance;

  const appliedFiltersText = Object.keys(filters).map(key => filters[key] && `${key}: ${filters[key]}`).filter(Boolean).join(', ');

  const callLogColumns = [
    {
      title: 'Call Code',
      dataIndex: 'callCode',
      key: 'callCode',
    },
    {
      title: 'Call Date',
      dataIndex: 'callDate',
      key: 'callDate',
    },
    {
      title: 'Last Date & Time',
      dataIndex: 'lastDateTime',
      key: 'lastDateTime',
    },
    {
      title: 'Pay Date',
      dataIndex: 'payDate',
      key: 'payDate',
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
    },
    {
      title: 'Logged by',
      dataIndex: ['user', 'username'],
      key: 'caller',
    },
  ];

  return (
    <div className="flexDiv">
      {showFilters && (
        <div className="modal-overlay" onClick={() => setShowFilters(false)}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h2>Filters</h2>
              <button onClick={() => setShowFilters(false)}>X</button>
            </div>
            <div className="modal-body">
              <form className="filters-form">
                <div className="filter-item">
                  <label>Customer:</label>
                  <input type="text" name="customer" value={filters.customer} onChange={handleFilterChange} />
                </div>
                <div className="filter-item">
                  <label>Client:</label>
                  <select name="client" value={filters.client} onChange={handleFilterChange}>
                    <option value="">Select Client</option>
                    {clients.map(client => (
                      <option key={client.id} value={client.id}>{client.name}</option>
                    ))}
                  </select>
                </div>
                <div className="filter-item">
                  <label>Risk Minimum:</label>
                  <input type="number" name="riskMinimum" value={filters.riskMinimum} onChange={handleFilterChange} />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {customers.length === 0 ? (
        <div>No customers found.</div>
      ) : (
        <div>
          <div className="call-customer-header">
            <div className="filters-pagination">
              <div className="pagination-buttons">
                <button onClick={handlePrevious} disabled={currentIndex === 0 && pagination.currentPage === 1}>Previous</button>
                <button onClick={handleNext} disabled={currentIndex === customers.length - 1 && pagination.currentPage === pagination.totalPages}>Next</button>
                {appliedFiltersText && <div className="applied-filters">Filters: {appliedFiltersText}</div>}
              </div>
              <div className="filters-actions">
                <button className="filters-button" onClick={() => setShowFilters(true)}>Show Filters</button>
                <button className="filters-button" onClick={handleClearFilters}>Clear Filters</button>
              </div>
            </div>

            <h2>Call Customer: {currentCustomer.name}</h2>
            <hr/>
          </div>

          <h3>Customer Details</h3>
          <p>Phone: {currentCustomer.phone}</p>
          <p>Email: {currentCustomer.email}</p>

          <p>Credit Limit: {currentCustomer.creditLimit || 'N/A'}</p>
          <p>Risk Rating: {currentCustomer.riskRating || 'N/A'}</p>

          <h3>Customer Contact Details</h3>
          {(currentCustomer.contacts || []).map(contact => (
            <div key={contact.id}>
              <p>Name: {contact.name}</p>
              <p>Email: {contact.email}</p>
              <p>Phone: {contact.phone}</p>
            </div>
          ))}

          <h3>Payment History</h3>
          <ul>
            {(currentCustomer.payments || []).map(payment => (
              <li key={payment.id}>{payment.date} - {payment.amount}</li>
            ))}
          </ul>

          <h3>Credit Memo History</h3>
          <ul>
            {(currentCustomer.creditMemos || []).map(memo => (
              <li key={memo.id}>{memo.date} - {memo.amount}</li>
            ))}
          </ul>

          <h3>Open Invoices</h3>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => {
                const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                return (
                  <tr key={key} {...headerGroupProps}>
                    {headerGroup.headers.map(column => {
                      const { key: columnKey, ...columnProps } = column.getHeaderProps();
                      return (
                        <th key={columnKey} {...columnProps}>{column.render('Header')}</th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                const { key: rowKey, ...rowProps } = row.getRowProps();
                return (
                  <tr key={rowKey} {...rowProps}>
                    {row.cells.map(cell => {
                      const { key: cellKey, ...cellProps } = cell.getCellProps();
                      return (
                        <td key={cellKey} {...cellProps}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          <button onClick={handleEmailDelinquentInvoices}>Email Delinquent Invoices</button>

          <h3>Call Log History</h3>
          <Table
            columns={callLogColumns}
            dataSource={callLogs}
            rowKey="id"
            pagination={false}
          />

          <h3>Log Call Details</h3>
          <div className="call-log-form">
            <div className="form-group">
              <label>Call Code:</label>
              <input type="text" value={callCode} onChange={(e) => setCallCode(e.target.value)} />
            </div>
            <div className="form-group">
              <label>Call Date:</label>
              <input type="date" value={callDate} onChange={(e) => setCallDate(e.target.value)} />
            </div>
            <div className="form-group">
              <label>Last Date & Time:</label>
              <input type="datetime-local" value={lastDateTime} onChange={(e) => setLastDateTime(e.target.value)} />
            </div>
            <div className="form-group">
              <label>Pay Date:</label>
              <input type="date" value={payDate} onChange={(e) => setPayDate(e.target.value)} />
            </div>
            <div className="form-group">
              <label>Comments:</label>
              <textarea value={note} onChange={(e) => setNote(e.target.value)} />
            </div>
            <button onClick={saveCallLog}>Save Call Log</button>
          </div>

          <h3>Call Notes</h3>
          <ul>
            {(currentCustomer.customerNotes || []).map(note => (
              <li key={note.id}>{note.note} by {note.createdBy}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CallCustomer;
