import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useTable, useSortBy, usePagination } from 'react-table';
import { saveAs } from 'file-saver';
import Modal from 'react-modal';
import axiosInstance from '../axiosConfig';
import config from '../config';
import './ScheduleDetails.css'; // Add custom CSS for styling

Modal.setAppElement('#root'); // Ensure accessibility

function ScheduleDetails() {
  const { scheduleId } = useParams();
  const [schedule, setSchedule] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [showMetadata, setShowMetadata] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchScheduleDetails = async () => {
      try {
        const response = await axiosInstance.get(`${config.API_URL}/schedules/${scheduleId}`);
        setSchedule(response.data);
      } catch (error) {
        console.error('Error fetching schedule details:', error);
      }
    };

    fetchScheduleDetails();
  }, [scheduleId]);

  const columns = useMemo(() => [
    {
      Header: 'Customer Account',
      accessor: 'customer.account'
    },
    {
      Header: 'Customer Name',
      accessor: 'customer.name'
    },
    {
      Header: 'Purchase Order Number',
      accessor: 'purchaseOrder.poNumber'
    },
    {
      Header: 'Invoice Number',
      accessor: 'invoiceNumber'
    },
    {
      Header: 'Invoice Date',
      accessor: 'invoiceDate',
      Cell: ({ value }) => new Date(value).toLocaleDateString() // Format date
    },
    {
      Header: 'Due Date',
      accessor: 'dueDate',
      Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : '' // Format date
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value) // Format as currency
    },
    {
      Header: 'Balance',
      accessor: 'balance',
      Cell: ({ value }) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value) // Format as currency
    },
    {
      Header: 'Description', // Add a new column for description
      accessor: 'description' // Assuming the description is stored under the 'description' field in each item
    },
    {
      Header: 'Actions',
      Cell: ({ row }) => (
        <div>
          <button className="view-metadata-button" onClick={() => handleShowMetadata(row.original.metadata)}>View Metadata</button>
          <button className="view-details-button" onClick={() => navigate(`/invoices/${row.original.id}`)}>View Details</button>
        </div>
      )
    }
  ], [navigate]);

  const data = useMemo(() => schedule ? schedule.items : [], [schedule]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = tableInstance;

  const handleShowMetadata = (metadata) => {
    setMetadata(metadata);
    setShowMetadata(true);
  };

  const fetchDocumentData = async (docId, documentName) => {
    try {
      const response = await axiosInstance.get(`${config.API_URL}/clientDocuments/download/${docId}`, {
        responseType: 'blob', // Specify response type as blob
      });
  
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', documentName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error fetching document data:', error);
    }
  };
  
  const downloadSchedulePDF = async () => {
    try {
      const response = await axiosInstance.get(`${config.API_URL}/schedules/${scheduleId}/pdf`, {
        responseType: 'blob', // Expecting a blob response for the PDF
      });
  
      // Use file-saver to trigger the download
      const blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, `Schedule_${schedule.scheduleNumber}.pdf`);
    } catch (error) {
      console.error('Error downloading schedule PDF:', error);
    }
  };

  if (!schedule) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flexDiv">
      <h1>Schedule Details</h1>
      <p><strong>Client Name:</strong> {schedule.client.name}</p> 
      <p><strong>Schedule Number:</strong> {schedule.scheduleNumber}</p>
      <p><strong>Date:</strong> {new Date(schedule.date).toLocaleDateString()}</p>
      <p><strong>Total Amount:</strong> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(schedule.totalAmount)}</p>
      <p><strong>Advance Amount:</strong> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(schedule.advanceAmount)}</p>

      <div className="buttonContainer">
        <button onClick={downloadSchedulePDF}>Print Schedule</button>
        <button onClick={() => handleShowMetadata(schedule.metadata)}>View Metadata</button>
      </div>
      
      <h2>Attachments</h2>
      {schedule.ClientDocuments && schedule.ClientDocuments.length > 0 ? (
        <ul>
          {schedule.ClientDocuments.map((doc) => (
            <li key={doc.id}>
              <button onClick={() => fetchDocumentData(doc.id, doc.documentName)}>
                {doc.documentName}
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No attachments available.</p>
      )}
      <h2>Invoices</h2>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key: headerGroupKey, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr key={headerGroupKey} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key: columnKey, ...restColumnProps } = column.getHeaderProps(column.getSortByToggleProps());
                  return (
                    <th key={columnKey} {...restColumnProps}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const { key: rowKey, ...restRowProps } = row.getRowProps();
            return (
              <tr key={rowKey} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key: cellKey, ...restCellProps } = cell.getCellProps();
                  return (
                    <td key={cellKey} {...restCellProps}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px' }}
          />
        </span>
        <select
          value={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <Modal
        isOpen={showMetadata}
        onRequestClose={() => setShowMetadata(false)}
        contentLabel="Metadata"
        className="metadata-modal"
        overlayClassName="metadata-overlay"
      >
        <div className="metadata-content">
          <div className="metadata-header">
            <h2>Metadata</h2>
            <button onClick={() => setShowMetadata(false)}>&times;</button>
          </div>
          <div className="metadata-body">
            <pre>{JSON.stringify(metadata, null, 2)}</pre>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ScheduleDetails;
